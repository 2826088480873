<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 30">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="m5.94 17.506-1.768-1.768a1.667 1.667 0 0 1 .106-2.463A16.492 16.492 0 0 1 11.172 10a16.25 16.25 0 0 1 7.071 0 16.49 16.49 0 0 1 6.918 3.3 1.667 1.667 0 0 1 .112 2.457l-1.768 1.768a1.667 1.667 0 0 1-2.192.165 10.702 10.702 0 0 0-2.068-1.244 1.667 1.667 0 0 1-.978-1.508V13.44a13.333 13.333 0 0 0-7.071 0v1.497a1.667 1.667 0 0 1-.979 1.508c-.734.336-1.427.753-2.068 1.244a1.667 1.667 0 0 1-2.21-.183Z"
            fill="currentColor"
        />
    </svg>
</template>
