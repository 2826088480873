<template>
    <div class="bg-halo tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-bg-alabaster">
        <div class="halo-1"></div>
        <div class="halo-2"></div>
        <div class="tw-z-40 tw-max-w-96 tw-p-4">
            <p class="tw-text-center tw-leading-tight">{{ session.area.assist_config.satisfaction_survey_question }}</p>
            <form class="tw-mt-4 tw-space-y-5" @submit.prevent="$router.replace({ name: 'home' })">
                <InputStars v-model="score" :disabled="loading" />
                <InputTextArea
                    v-model="comment"
                    v-if="session.area.assist_config.satisfaction_survey_allow_comment"
                    :disabled="loading"
                    :placeholder="t('widget.satisfaction.comment_placeholder')"
                />
                <div class="tw-flex tw-space-x-2">
                    <ApendayButton class="tw-w-full" :loading="loading" color="primary" @click.prevent="submit">
                        {{ t('actions.validate') }}
                    </ApendayButton>
                </div>
            </form>
        </div>
    </div>
</template>
<script setup lang="ts">
    import ApendayButton from '@/components/ApendayButton.vue';
    import InputStars from '@/components/live/forms/InputStars.vue';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';
    import InputTextArea from '@/components/forms/InputTextArea.vue';
    import { ref } from 'vue';
    import axios from '@/axios';
    import { useRouter } from 'vue-router';
    import { toast } from '@/composables/toast';
    import { useSessionStore } from '@/store/session';

    const sessionStore = useSessionStore();
    const { session } = storeToRefs(sessionStore);
    const router = useRouter();

    const comment = ref('');
    const score = ref(0);
    const loading = ref(false);

    const { t } = useI18n();

    async function submit() {
        if (score.value === 0) {
            toast({
                text: t('widget.satisfaction.error_score'),
                type: 'error',
            });
            return;
        }
        loading.value = true;
        try {
            await axios.request({
                method: 'PUT',
                url: `api/assist/${session.value.id}/rate-session`,
                data: {
                    satisfaction_score: score.value,
                    satisfaction_comment: comment.value.length === 0 ? null : comment.value,
                },
            });
            loading.value = false;
            router.replace({ name: 'closed' });
        } catch (e) {
            loading.value = false;
            toast({
                text: e.customErrorMsg ?? e.toString(),
                type: 'error',
            });
        }
    }
</script>
