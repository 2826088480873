<template>
    <error v-slot="{ errorText }" :error="error">
        <div class="tw-relative" v-bind="$attrs">
            <textarea
                class="apd-textarea"
                :placeholder="placeholder"
                :disabled="disabled"
                :value="modelValue"
                @input="emit('update:modelValue', $event.target.value)"
                :class="{
                    '!tw-border-danger !tw-text-danger placeholder:!tw-text-danger': errorText,
                }"
            />
        </div>
    </error>
</template>

<script setup lang="ts">
    import Error from '@/components/forms/Error.vue';

    defineProps<{
        modelValue?: string;
        placeholder?: string;
        disabled?: boolean;
        error?: string | object | boolean;
    }>();

    const emit = defineEmits<{
        (e: 'update:modelValue', value: string): void;
    }>();
</script>

<style lang="scss" scoped>
    .apd-textarea {
        background: transparent;
        border-radius: 5px;
        border: 1px solid var(--color-silver);
        font-size: 13px;
        letter-spacing: 0.03em;
        color: var(--color-body);
        width: 100%;
        padding: 12px;
        min-height: 100px;
    }
</style>
