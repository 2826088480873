import { getEnv } from '@/env/env';
import router from '@/router/assist';
import { BrowserTracing } from '@sentry/tracing';
import { Replay, init, vueRouterInstrumentation } from '@sentry/vue';
import type { App } from 'vue';

export const initSentry = (app: App) => {
    init({
        app,
        enabled: import.meta.env.PROD,
        dsn: 'https://63a02d56ec5d40e5865382b45e3ae6e1@o1290556.ingest.sentry.io/4506281958703104',
        release: getEnv().releaseName,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: vueRouterInstrumentation(router),
                tracePropagationTargets: ['assist.apenday.com', 'preprod.assist.apenday.com', 'int.assist.apenday.com', /^\//],
            }),
            new Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracesSampleRate: 0.3,
        environment: getEnv().envName,
    });
};
