<template>
    <div class="tw-bg-white tw-p-8">
        <button class="tw-absolute -tw-left-5 tw-top-3 tw-flex tw-rounded-full tw-bg-white tw-p-1 tw-shadow-md" @click="emit('close')">
            <IonIcon class="tw-h-8 tw-w-8" src="assets/icons/chevron-right.svg" />
        </button>

        <label class="tw-text-sm tw-font-semibold" for="camera-select">{{ t('interaction.videoView.settings.camera') }}</label>
        <div class="tw-grow">
            <select
                class="tw-w-full tw-border-b tw-border-black tw-p-2"
                id="camera-select"
                v-model="camera"
                @change="onChange($event, 'videoinput')"
                name="camera"
            >
                <option v-for="camera in cameras" :value="camera.deviceId" :key="camera.deviceId">{{ camera.label }}</option>
            </select>
        </div>

        <label class="form-label tw-mt-5 tw-block tw-text-sm tw-font-semibold" for="mic-select">{{
            t('interaction.videoView.settings.mic')
        }}</label>
        <div class="tw-grow">
            <select
                class="tw-w-full tw-border-b tw-border-black tw-p-2"
                id="mic-select"
                v-model="mic"
                @change="onChange($event, 'audioinput')"
                name="mic"
            >
                <option v-for="mic in mics" :value="mic.deviceId" :key="mic.deviceId">{{ mic.label }}</option>
            </select>
        </div>

        <!--        <label class="tw-mt-5 tw-block tw-font-semibold" for="speaker-select">{{ t('interaction.videoView.settings.speaker') }}</label>-->
        <!--        <div class="tw-grow">-->
        <!--            <select-->
        <!--                class="tw-w-full tw-border-b tw-border-black tw-p-2"-->
        <!--                id="speaker-select"-->
        <!--                v-model="speaker"-->
        <!--                @change="onChange($event, 'audiooutput')"-->
        <!--                name="speaker"-->
        <!--            >-->
        <!--                <option v-for="speaker in speakers" :value="speaker.deviceId" :key="speaker.deviceId">{{ speaker.label }}</option>-->
        <!--            </select>-->
        <!--        </div>-->
    </div>
</template>

<script setup lang="ts">
    import ApendayVideo, { DevicesIds, MediaDeviceKind } from '@/classes/apenday-video';
    import { IonIcon } from '@ionic/vue';
    import { onMounted, onUnmounted, ref, toRefs } from 'vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const emit = defineEmits<{
        (e: 'close');
    }>();

    const props = defineProps<{
        client: ApendayVideo;
    }>();

    const onChange = ({ target: { value: deviceId } }, kind: MediaDeviceKind) => client.value.changeDevice(deviceId, kind);

    const { client } = toRefs(props);

    const cameras = ref([]);
    const mics = ref([]);
    // const speakers = ref([]);

    const camera = ref(null);
    const mic = ref(null);
    // const speaker = ref(null);

    const init = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log('enumerateDevices() not supported.');
            return;
        }
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach((device) => {
                if (device.kind === 'videoinput') cameras.value.push(device);
                if (device.kind === 'audioinput') mics.value.push(device);
                // if (device.kind === 'audiooutput') speakers.value.push(device);
            });
            if (!camera.value) client.value.changeDevice(cameras.value?.[0].deviceId, 'videoinput');
            if (!mic.value) client.value.changeDevice(mics.value?.[0].deviceId, 'audioinput');
            // if (!speaker.value) client.value.changeDevice(speakers.value?.[0].deviceId, 'audiooutput');
        } catch (err) {
            console.log(`${err.name}: ${err.message}`);
        }
    };

    init();

    const deviceIdChangeHandler = (deviceId: DevicesIds) => {
        mic.value = deviceId.micDeviceId;
        camera.value = deviceId.videoDeviceId;
        // speaker.value = deviceId.speakerDeviceId;
    };

    onMounted(async () => {
        client.value.onWithReplay('deviceIdChange', deviceIdChangeHandler);
    });

    onUnmounted(async () => {
        client.value.off('deviceIdChange', deviceIdChangeHandler);
    });
</script>

<style>
    .aside-flip-enter-active,
    .aside-flip-leave-active {
        transition: transform 0.2s cubic-bezier(0.04, 0.69, 0.37, 0.97);
    }

    .aside-flip-enter-from,
    .aside-flip-leave-to {
        transform: translateX(110%);
    }
</style>
