import Axios from '@/axios';
import { toast } from '@/composables/toast';
import i18n from '@/i18n';
import { wait } from '@/utils/wait';
import { ref, Ref } from 'vue';

export const useScreenshot = ({ remoteMediaRefs, url }: { remoteMediaRefs: Ref<HTMLElement[]>; url: string }) => {
    const isLoading = ref(false);

    const takeScreenshot = async () => {
        if (!remoteMediaRefs.value.length || isLoading.value) return;

        const firstRemoteWrapperDiv = <HTMLDivElement>remoteMediaRefs.value[0];
        const video = firstRemoteWrapperDiv.querySelector('video');
        const w = video.videoWidth;
        const h = video.videoHeight;

        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, w, h);

        canvas.toBlob(
            async (blob) => {
                isLoading.value = true;
                try {
                    await send(blob);
                    showNotif(canvas);
                } catch (e) {
                    console.error(e);
                    alert(i18n.global.t('common.errorServer'));
                }
                isLoading.value = false;
            },
            'image/jpeg',
            0.95,
        );
    };

    const send = async (blob) => {
        const data = new FormData();
        data.append('file', blob);
        await Axios.request({
            method: 'post',
            url,
            data,
        });
    };

    const showNotif = async (canvas) => {
        const firstRemoteWrapperDiv = <HTMLDivElement>remoteMediaRefs.value[0];
        const flash = document.createElement('div');
        flash.classList.add('tw-absolute', 'tw-inset-0', 'tw-z-index-30', 'tw-bg-white', 'screenshot-flash');
        firstRemoteWrapperDiv.appendChild(flash);
        toast({
            text: i18n.global.t('interaction.videoView.screenshotNotification'),
            type: 'success',
        });

        await wait(600); // wait for the animation to finish
        flash.remove();
    };

    return {
        takeScreenshot,
        isLoading,
    };
};
