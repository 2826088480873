import { useSessionStore } from '@/store/session';
import Closed from '@/views/assist/Closed.vue';
import Satisfaction from '@/views/assist/Satisfaction.vue';
import View from '@/views/assist/View.vue';
import Welcome from '@/views/assist/Welcome.vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
    },
    {
        path: '/:id/',
        name: 'pivotView',
        beforeEnter: async ({ params }) => {
            const sessionStore = useSessionStore();
            try {
                if (!sessionStore.session) {
                    const session = await sessionStore.fetchSession(params.id);
                    await sessionStore.setColors();
                    if (session.status === 'closed') return { name: 'closed', params };
                }
            } catch (e) {
                console.error("La session n'existe pas/plus ou est désactivé");
                return { name: 'closed', params };
            }
        },
        children: [
            {
                path: '',
                name: 'root',
                component: View,
            },
            {
                path: 'closed',
                name: 'closed',
                component: Closed,
            },
            {
                path: 'satisfaction',
                name: 'satisfaction',
                component: Satisfaction,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

export default router;
