<template>
    <slot :errorText="errorText"></slot>
    <transition>
        <div
            class="tw-my-2.5 tw-space-y-2 tw-rounded-[5px] tw-bg-danger tw-p-3.5 tw-text-[13px] tw-leading-[1.6] tw-text-white"
            v-if="errorText && !hideErrorText"
        >
            <p v-html="errorText"></p>
        </div>
    </transition>
</template>
<script lang="ts">
    import { castArray, filter, forEach, isObject, join, map } from 'lodash';
    import { computed, defineComponent, PropType, toRefs } from 'vue';
    import { useI18n } from 'vue-i18n';

    export default defineComponent({
        props: {
            error: {
                type: [String, Object, Array, Boolean] as PropType<ErrorType>,
                required: false,
            },
            hideErrorText: {
                type: Boolean,
                required: false,
            },
            errors: {
                type: Object,
                required: false,
            },
            validationSchema: {
                type: [Object, Array],
                required: false,
            },
        },
        setup(props) {
            const { error, errors, validationSchema } = toRefs(props);
            const { t } = useI18n();

            const ignoredKeys = computed(() => {
                const fieldsKeys: string[] = [];
                if (validationSchema.value) {
                    const array = castArray(validationSchema.value);
                    array.forEach((vs: { _nodes: string[] }) => {
                        forEach(vs._nodes, (key) => {
                            fieldsKeys.push(key);
                        });
                    });
                }

                return fieldsKeys;
            });

            const errorText = computed(() => {
                // Field error
                let arrayErrors = castArray(error.value);
                arrayErrors = filter(arrayErrors, (error) => error);
                arrayErrors = map(arrayErrors, (error: { key: string; values: Record<string, any> } | string) => {
                    if (isObject(error)) {
                        return t(error.key, error.values);
                    }
                    return error.includes(' ') ? error : t(error);
                });

                // Global Errors not catched
                if (errors.value) {
                    forEach(errors.value, (value, key) => {
                        if (!subStringIsInArray(ignoredKeys.value, key)) {
                            if (isObject(value)) {
                                arrayErrors.push(t(value['key'], value['values']));
                            } else {
                                arrayErrors.push(value.includes(' ') ? value : t(value));
                            }
                        }
                    });
                }

                return arrayErrors.length ? join(arrayErrors, '<br/>') : false;
            });

            return {
                errorText,
            };
        },
    });

    const subStringIsInArray = (array: string[], key) => {
        let result = false;
        array.forEach((str) => {
            if (key.includes(str)) result = true;
        });
        return result;
    };
</script>

<style scoped lang="scss">
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.2s ease;
    }

    .v-enter-from {
        opacity: 0;
    }

    .v-leave-to {
        opacity: 0;
    }
</style>
