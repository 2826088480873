// @ts-nocheck
import { getEnv } from '@/env/env';
import i18n from '@/i18n';
import { Network } from '@capacitor/network';
import Axios from 'axios';
import { forEach, isNull } from 'lodash';

const axiosFront = Axios.create({
    baseURL: getEnv().baseUrl,
    timeout: 40000,
    auth: {
        username: 'front_api',
        password: 'Front4piP4ssw0rd!',
    },
});

// Requests
axiosFront.interceptors.request.use(
    async function (config) {
        if (!config.headers) return config;

        // Remove empty params keys
        if (config['params']) {
            forEach(config['params'], (value, key) => {
                if (isNull(value)) delete config['params'][key];
            });
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

// Responses
axiosFront.interceptors.response.use(
    ({ data }) => data,
    async function (intercept) {
        if (intercept.response) {
            if (intercept.response.data.error) {
                intercept.customErrorMsg = intercept.response.data.error.message;
            } else if (intercept.response.data.detail && intercept.response.data.status) {
                intercept.customErrorMsg = `${intercept.response.data.detail} (code : ${intercept.response.data.status})`;
            } else if (intercept.response.data.title && intercept.response.data.status) {
                intercept.customErrorMsg = `${intercept.response.data.title} (code : ${intercept.response.data.status})`;
            } else if (intercept.response.data.errors) {
                intercept.customErrorMsg = intercept.response.data.errors[0].message;
            } else {
                intercept.customErrorMsg = i18n.global.t('common.errorServer');
            }
        } else {
            const { connected } = await Network.getStatus();
            intercept.customErrorMsg = connected ? i18n.global.t('common.errorServer') : i18n.global.t('common.networkError');
        }

        return Promise.reject(intercept);
    },
);

export default axiosFront;
