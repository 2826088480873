// organize-imports-ignore
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import i18n from '@/i18n';
import router from '@/router/assist';
import '@/yup';
import App from './AppAssist.vue';

import './theme/assist.scss';
import { initSentry } from '@/sentry-assist';

const app = createApp(App).use(createPinia()).use(i18n).use(router);

initSentry(app);

router.isReady().then(() => {
    app.mount('#app');
});
