<template>
    <div class="bg-halo tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-bg-alabaster tw-p-6">
        <div class="halo-1"></div>
        <div class="halo-2"></div>
        <img
            class="tw-mb-5 tw-max-w-[150px]"
            v-if="session.area?.logo?.content_url"
            :src="session.area.logo.content_url"
            :alt="session.area?.name"
        />
        <p
            class="tw-max-w-[900px] tw-text-center tw-text-gray-600"
            v-html="session.area.assist_session_end_message ?? t('assist.closedSession')"
        ></p>
    </div>
</template>
<script setup lang="ts">
    import { useSessionStore } from '@/store/session';
    import { storeToRefs } from 'pinia';
    import { useI18n } from 'vue-i18n';

    const sessionStore = useSessionStore();
    const { session } = storeToRefs(sessionStore);
    const { t } = useI18n();
</script>
