<template>
    <popper
        class="menu-tooltip"
        :offset-distance="offsetDistance"
        :disabled="isTablet || disabled"
        hover
        :placement="placement"
        :content="content"
    >
        <slot></slot>
    </popper>
</template>

<script setup lang="ts">
    import responsive from '@/composables/responsive';
    import { Placement } from '@popperjs/core';
    import Popper from 'vue3-popper';

    withDefaults(
        defineProps<{
            content?: string;
            placement?: Placement;
            disabled?: boolean;
            offsetDistance?: string;
        }>(),
        {
            placement: 'bottom',
        },
    );

    const { isTablet } = responsive;
</script>
