<template>
    <div class="tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-space-x-0.5 tw-rounded-full tw-bg-[var(--color-primary)]">
        <div class="tw-h-1 tw-w-1 tw-rounded-sm tw-bg-white" :style="`height: ${5 * percent + 4}px`"></div>
        <div class="tw-w-1 tw-rounded-sm tw-bg-white" :style="`height: ${14 * percent + 4}px`"></div>
        <div class="tw-h-1 tw-w-1 tw-rounded-sm tw-bg-white" :style="`height: ${5 * percent + 4}px`"></div>
    </div>
</template>

<script setup lang="ts">
    import { computed, toRefs } from 'vue';

    const props = withDefaults(
        defineProps<{
            level: number;
        }>(),
        {
            level: 0,
        },
    );
    const { level } = toRefs(props);

    const percent = computed(() => {
        const _level = level.value < 0 ? 0 : level.value;
        return _level / 10;
    });
</script>
