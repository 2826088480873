<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
            d="M19.5 17H4c-1.1 0-2-.9-2-2V5c0-1.1.3-1.7 1.5-1.9M8.8 3H20c1.1 0 2 .9 2 2v8.7M8 21h8m-4-4v4"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="bevel"
            stroke="currentColor"
        />
        <path d="m1.4 1.4 21.2 18.2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" />
    </svg>
</template>
<script setup lang="ts"></script>
