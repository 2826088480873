<template>
    <div class="tw-flex tw-h-16 tw-items-center tw-justify-between tw-rounded-xl tw-bg-gray-100 tw-px-6">
        <button
            class="tw-p-2 tw-text-gray-300"
            v-for="i in 5"
            type="button"
            :key="i"
            :class="{
                'tw-text-primary': i <= modelValue,
                'hover:tw-text-primary': !disabled,
            }"
            @click.prevent="onClick(i)"
        >
            <icon-star class="tw-w-6" />
        </button>
    </div>
</template>
<script setup lang="ts">
    import IconStar from '@/components/icons/IconStar.vue';
    import { toRefs } from 'vue';

    const props = defineProps<{
        modelValue: number;
        disabled?: boolean;
    }>();

    const { modelValue, disabled } = toRefs(props);
    const emit = defineEmits<{
        (e: 'update:modelValue', value: number): void;
    }>();

    function onClick(value: number) {
        if (disabled.value) {
            return;
        }
        if (modelValue.value === value) {
            emit('update:modelValue', 0);
        } else {
            emit('update:modelValue', value);
        }
    }
</script>
