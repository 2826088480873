import AxiosFront from '@/axios';
import { castArray } from 'lodash';
import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

export const useSessionStore = defineStore('session', () => {
    const session = ref<Session>();

    // Mutators
    const updateSession = (item: Session) => {
        session.value = item;
    };

    const fetchSession = async (routeId: string | string[] = '1c0f9ee0-04c6-4bcf-bb45-19b905da7a32'): Promise<Session> => {
        const id = castArray(routeId)[0];
        const response = await AxiosFront.request<any, Session>({
            method: 'get',
            url: 'api/assist/' + id,
        });
        session.value = response;

        return response;
    };

    const setColors = () => {
        if (session.value?.area?.primary_color) {
            document.documentElement.style.setProperty('--color-primary', session.value.area.primary_color);
        }
        if (session.value?.area?.secondary_color) {
            document.documentElement.style.setProperty('--color-secondary', session.value.area.secondary_color);
        }
    };

    // Actions
    return {
        session: readonly(session),
        fetchSession,
        updateSession,
        setColors,
    };
});
