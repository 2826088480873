<template>
    <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between" ref="viewRef">
        <div
            class="tw-absolute tw-left-2 tw-top-2 tw-z-30 tw-h-3 tw-w-3 tw-animate-pulse tw-rounded-full tw-bg-red-500"
            v-if="isRecording"
        ></div>
        <transition name="popper">
            <div
                class="tw-absolute tw-top-0 tw-z-50 tw-w-full tw-p-2 tw-text-center tw-text-base tw-text-white"
                v-if="toastMessage"
                :class="{
                    'tw-bg-red-600': toastMessageIsError,
                    'tw-bg-green-600': !toastMessageIsError,
                }"
            >
                <p class="tw-font-semibold">{{ toastMessage }}</p>
            </div>
        </transition>

        <div></div>
        <div class="tw-relative tw-h-full tw-overflow-hidden tw-bg-neutral-800">
            <div class="tw-absolute tw-inset-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-tracking-normal tw-text-gray-400">
                <icon-user class="tw-mb-1 tw-w-6" v-if="isLiveApp" />
                <icon-user-x class="tw-mb-1 tw-w-6" v-else />
                <p>{{ isLiveApp ? interaction.widget.waiting_message_video : t('interaction.videoView.empty') }}</p>
                <lds-ellipsis class="tw-h-8 tw-w-8 tw-fill-gray-500" v-if="isLiveApp" />
            </div>
            <!-- remoteParticipants -->
            <div
                class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-bg-body"
                v-for="ui in participantUis"
                :key="ui.sid"
                :id="`remote-media-div-${ui.sid}`"
                ref="remoteMediaRefs"
            >
                <IconVideoOff
                    class="tw-absolute tw-left-1/2 tw-top-1/2 tw-h-[30%] tw-w-[30%] -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-zinc-500"
                />
                <div class="tw-absolute tw-bottom-0 tw-left-0 tw-z-10 tw-flex tw-items-end">
                    <div
                        class="tw-flex tw-grow tw-items-center tw-space-x-4 tw-bg-body tw-bg-opacity-70 tw-p-2 tw-text-xs tw-font-semibold tw-tracking-wide tw-text-white"
                    >
                        <span v-if="isAdminApp">
                            {{ ui.identity }}
                        </span>
                        <icon-video-on class="tw-w-3.5" v-if="ui.isVideoEnabled" />
                        <icon-video-off class="tw-w-3.5" v-else />
                        <icon-mic-on class="tw-w-3.5" v-if="ui.isAudioEnabled" />
                        <icon-mic-off class="tw-w-3.5" v-else />
                    </div>
                </div>
            </div>
            <!-- localParticipant -->
            <div
                class="tw-absolute tw-z-20 tw-flex tw-items-end tw-justify-end tw-overflow-hidden tw-transition-all"
                id="local-media-div"
                :class="{
                    'tw-bottom-[50%] tw-right-[50%] tw-h-full tw-w-full tw-translate-x-1/2 tw-translate-y-1/2 tw-items-center tw-justify-center':
                        !connected || localVideoExpanded,
                    'tw-bottom-0 tw-right-0': connected && !(pointerEnabled && isAssistApp && (videoEnabled || screenSharingEnabled)),
                    'tw-h-[140px] tw-w-[140px] 2xl:tw-h-[110px] 2xl:tw-w-[150px]':
                        connected && !(pointerEnabled && isAssistApp && (videoEnabled || screenSharingEnabled)) && !localVideoExpanded,
                    'tw-bg-body': (!videoEnabled && !screenSharingEnabled) || localVideoExpanded,
                    'tw-bottom-0 tw-right-0 tw-flex tw-h-full tw-w-full tw-translate-x-0 tw-translate-y-0 tw-items-center tw-justify-center tw-overflow-hidden tw-bg-green-500':
                        pointerEnabled && isAssistApp,
                }"
            >
                <div
                    class="tw-absolute tw-left-1/2 tw-top-1/2 tw-flex tw-h-[30%] tw-w-[30%] -tw-translate-x-1/2 -tw-translate-y-1/2 tw-items-center tw-justify-center"
                    v-if="!videoEnabled"
                >
                    <icon-video-off class="tw-h-full tw-w-full tw-text-zinc-500" v-if="interaction.status !== 'closed'" />
                    <p class="tw-text-center tw-tracking-wide tw-text-zinc-400" v-else>
                        {{ $t('interaction.videoView.closed') }}
                    </p>
                </div>
                <div class="tw-absolute tw-bottom-2 tw-right-2 tw-z-10 tw-text-white" v-if="audioEnabled">
                    <audio-level :level="audioLevel" />
                </div>
                <div
                    class="tw-absolute tw-inset-0 tw-z-40 tw-grid tw-cursor-pointer tw-bg-zinc-500 tw-bg-opacity-50 tw-text-gray-200 tw-opacity-0 tw-transition-all lg:hover:tw-opacity-100"
                    v-if="connected"
                    :class="{
                        '!tw-grid-cols-1': localVideoExpanded || pointerEnabled,
                    }"
                >
                    <div
                        class="tw-flex tw-items-center tw-justify-center tw-transition-all hover:tw-scale-110 hover:tw-text-white"
                        v-if="!pointerEnabled"
                        @click="onLocalVideoExpandClick"
                    >
                        <IconMaximizeAlt class="tw-w-8 -tw-scale-x-100" />
                    </div>
                </div>
            </div>
            <!-- Settings -->
            <VideoButton
                class="tw-absolute tw-right-4 tw-top-4 tw-z-30"
                placement="left"
                :tooltip="t('interaction.videoView.tooltips.params')"
                @click.prevent="showSettings = true"
            >
                <icon-settings class="tw-h-6 tw-w-6" />
            </VideoButton>
            <transition name="aside-flip">
                <video-settings
                    class="tw-absolute tw-right-0 tw-top-0 tw-z-30 tw-h-full tw-w-full tw-max-w-[360px] tw-rounded-l-xl tw-shadow-md"
                    v-if="showSettings"
                    :client="client"
                    @close="showSettings = false"
                />
            </transition>
        </div>
        <!-- Control Bar -->
        <div
            class="tw-flex tw-items-center tw-justify-between tw-rounded-b-lg tw-bg-[var(--color-primary)] tw-p-3"
            v-if="interaction.status !== 'closed'"
        >
            <VideoButton
                :color-class="connected ? 'tw-bg-red-500' : 'tw-bg-green-500'"
                :label="bpUpXl || !isAdminApp ? t(connected ? 'interaction.videoView.leave' : 'interaction.videoView.enter') : null"
                :loading="isConnecting || videoMuteLoading || audioMuteLoading"
                @click.prevent="connected ? disconnect() : enterRoom()"
            >
                <icon-leave-phone class="tw-w-7" v-if="connected" />
                <icon-phone-call class="tw-mr-0.5 tw-mt-0.5 tw-w-5" v-else />
            </VideoButton>

            <div
                class="tw-flex"
                :class="{
                    'tw-space-x-1 2xl:tw-space-x-3': isAdminApp,
                    'tw-space-x-3': !isAdminApp,
                }"
            >
                <VideoButton
                    v-if="isAdminApp && isAdminAssistView && participantUis.length"
                    @click.prevent="startPointer()"
                    :tooltip="
                        t(pointerEnabled ? 'interaction.videoView.tooltips.pointer_off' : 'interaction.videoView.tooltips.pointer_on')
                    "
                    :loading="pointerLoading"
                    :color-class="pointerEnabled ? 'tw-bg-green-500 tw-animate-pulse' : ''"
                >
                    <IconCrossHair class="tw-w-5" />
                </VideoButton>
                <VideoButton
                    v-if="((isAdminApp && isAdminAssistView) || isAssistApp) && participantUis.length && isShareScreenAvailable"
                    @click.prevent="shareScreen()"
                    :tooltip="
                        t(
                            screenSharingEnabled
                                ? 'interaction.videoView.tooltips.screen_share_off'
                                : 'interaction.videoView.tooltips.screen_share_on',
                        )
                    "
                    :loading="screenShareLoading"
                    :color-class="screenSharingEnabled ? 'tw-bg-green-500 tw-animate-pulse' : ''"
                >
                    <icon-screen-share-off class="tw-w-5" v-if="screenSharingEnabled" />
                    <icon-screen-share-on class="tw-w-5" v-else />
                </VideoButton>
                <VideoButton
                    v-if="isAdminApp && isAdminAssistView && participantUis.length"
                    :loading="screenShotIsLoading"
                    :tooltip="t('interaction.videoView.tooltips.screenshot')"
                    @click.prevent="takeScreenshot()"
                >
                    <icon-photo class="tw-w-5" />
                </VideoButton>
                <VideoButton
                    v-if="isAdminApp && isAdminAssistView && participantUis.length"
                    :loading="recordIsLoading"
                    :tooltip="t(isRecording ? 'interaction.videoView.tooltips.record_off' : 'interaction.videoView.tooltips.record_on')"
                    @click.prevent="toggleRecord()"
                >
                    <icon-stop-record class="tw-animate-pulse" v-if="isRecording" />
                    <icon-record v-else />
                </VideoButton>
                <VideoButton
                    v-if="iSFullScreenSupported"
                    @click.prevent="toggleFullScreen()"
                    :tooltip="
                        t(isFullscreen ? 'interaction.videoView.tooltips.fullscreen_off' : 'interaction.videoView.tooltips.fullscreen_on')
                    "
                >
                    <icon-minimize class="tw-h-5" v-if="isFullscreen" />
                    <icon-maximize class="tw-h-5" v-else />
                </VideoButton>
                <VideoButton
                    :class="{
                        'tw-opacity-40': isRecording,
                    }"
                    @click.prevent="toggleVideo()"
                    :tooltip="t(videoEnabled ? 'interaction.videoView.tooltips.camera_off' : 'interaction.videoView.tooltips.camera_on')"
                    :loading="videoMuteLoading"
                    :color-class="videoEnabled ? '' : 'tw-bg-red-500'"
                >
                    <icon-video-on class="tw-h-5" v-if="videoEnabled" />
                    <icon-video-off class="tw-h-5" v-else />
                </VideoButton>
                <VideoButton
                    :class="{
                        'tw-opacity-40': isRecording,
                    }"
                    @click.prevent="toggleAudio()"
                    :tooltip="t(audioEnabled ? 'interaction.videoView.tooltips.audio_off' : 'interaction.videoView.tooltips.audio_on')"
                    :loading="audioMuteLoading"
                    :color-class="audioEnabled ? '' : 'tw-bg-red-500'"
                >
                    <icon-mic-on class="tw-h-5" v-if="audioEnabled" />
                    <icon-mic-off class="tw-h-5" v-else />
                </VideoButton>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import Axios from '@/axios';
    import type ApendayVideo from '@/classes/apenday-video';
    import type { VideoParticipantUI } from '@/classes/apenday-video';
    import IconLeavePhone from '@/components/icons/IconLeavePhone.vue';
    import IconMaximize from '@/components/icons/IconMaximize.vue';
    import IconMicOff from '@/components/icons/IconMicOff.vue';
    import IconMicOn from '@/components/icons/IconMicOn.vue';
    import IconMinimize from '@/components/icons/IconMinimize.vue';
    import IconPhoneCall from '@/components/icons/IconPhoneCall.vue';
    import IconPhoto from '@/components/icons/IconPhoto.vue';
    import IconRecord from '@/components/icons/IconRecord.vue';
    import IconScreenShareOff from '@/components/icons/IconScreenShareOff.vue';
    import IconScreenShareOn from '@/components/icons/IconScreenShareOn.vue';
    import IconSettings from '@/components/icons/IconSettings.vue';
    import IconStopRecord from '@/components/icons/IconStopRecord.vue';
    import IconUser from '@/components/icons/IconUser.vue';
    import IconUserX from '@/components/icons/IconUserX.vue';
    import IconVideoOff from '@/components/icons/IconVideoOff.vue';
    import IconVideoOn from '@/components/icons/IconVideoOn.vue';
    import LdsEllipsis from '@/components/LdsEllipsis.vue';
    import AudioLevel from '@/components/video/AudioLevel.vue';
    import VideoButton from '@/components/video/VideoButton.vue';
    import VideoSettings from '@/components/video/VideoSettings.vue';
    import { useInteraction } from '@/composables/interaction';
    import { useScreenshot } from '@/composables/screenshot';
    import Dayjs from '@/dayjs';
    import { generateId } from '@/utils/generateId';
    import { wait } from '@/utils/wait';
    import { useFullscreen } from '@vueuse/core';
    import { computed, onMounted, onUnmounted, ref, toRefs } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useRoute } from 'vue-router';
    import IconCrossHair from '@/components/icons/IconCrossHair.vue';
    import IconMaximizeAlt from '@/components/icons/IconMaximizeAlt.vue';
    import ArrowRight from '@/components/icons/ArrowRight.vue';
    import responsive from '@/composables/responsive';

    const props = defineProps<{
        interaction: Interaction | Session;
    }>();

    const emit = defineEmits<{
        (e: 'update:interaction', interaction: Interaction | Session);
        (e: 'end'): void;
    }>();

    const route = useRoute();
    let client: ApendayVideo;
    const { interaction } = toRefs(props);
    const audioEnabled = ref(false);
    const videoEnabled = ref(false);
    const screenSharingEnabled = ref(false);
    const pointerEnabled = ref(false);
    const connected = ref(false);
    const isRecording = ref(false);
    const isConnecting = ref(false);
    const recordIsLoading = ref(false);
    const pointerLoading = ref(false);
    const participantUis = ref<VideoParticipantUI[]>([]);
    const audioMuteLoading = ref(false);
    const videoMuteLoading = ref(false);
    const screenShareLoading = ref(false);
    const localVideoExpanded = ref(false);
    const audioLevel = ref(0);
    const isLiveApp = import.meta.env.VITE_APP === 'live';
    const isAdminApp = import.meta.env.VITE_APP === 'admin';
    const isAssistApp = import.meta.env.VITE_APP === 'assist';
    const isAdminAssistView = computed(() => {
        return route.name === 'session';
    });
    const isInteraction = !!interaction.value.widget;
    const viewRef = ref(null);
    const remoteMediaRefs = ref(null);
    const { t } = useI18n();
    const toastMessage = ref('');
    const toastMessageIsError = ref(false);
    const showSettings = ref(false);
    let waitId = '';
    const reconnectTry = ref(0);
    const isShareScreenAvailable = navigator.mediaDevices.getDisplayMedia !== undefined;
    const { bpUpXl } = responsive;

    const { getInstance, updateTwilioToken } = useInteraction(interaction);
    const { isFullscreen, isSupported: iSFullScreenSupported, toggle: toggleFullScreen } = useFullscreen(viewRef);
    const { takeScreenshot, isLoading: screenShotIsLoading } = useScreenshot({
        remoteMediaRefs,
        url: `/api/private/assist/sessions/${interaction.value.id}/add-record`,
    });

    const toggleRecord = async () => {
        const minimumTime = Dayjs(interaction.value['updated_at']).add(8, 'second');
        if (interaction.value['recording'] && Dayjs().isBefore(minimumTime)) {
            // Prevent small recording under 4 secs
            return;
        }
        recordIsLoading.value = true;
        try {
            const session = await Axios.request<Session, Session>({
                method: 'put',
                url: `/api/private/assist/sessions/${interaction.value.id}`,
                data: {
                    recording: !isRecording.value,
                },
            });
            emit('update:interaction', session);
        } catch (e) {
            console.error(e);
            recordIsLoading.value = false;
        }
    };

    const enterRoom = async () => {
        isConnecting.value = true;
        try {
            await client.enterRoom();
        } catch (e) {
            isConnecting.value = false;
            if (e.code === 20104 && reconnectTry.value < 2) {
                reconnectTry.value++;
                // "Access Token expired or expiration date invalid"
                await updateTwilioToken();
                enterRoom();
            } else {
                emit('end');
            }
        }
    };

    const toggleAudio = async () => {
        if (isRecording.value) {
            showToastMessage(t('interaction.videoView.record_impossible'), true);
            return;
        }
        audioMuteLoading.value = true;
        await client.toggleLocalAudio();
    };

    const toggleVideo = async () => {
        if (screenSharingEnabled.value) {
            showToastMessage(t('interaction.videoView.impossible_during_screen_share'), true);
            return;
        }
        if (isRecording.value) {
            showToastMessage(t('interaction.videoView.record_impossible'), true);
            return;
        }
        videoMuteLoading.value = true;
        await client.toggleLocalVideo();
    };

    const disconnect = async () => {
        try {
            await client.shutdown();
        } catch (e) {
            console.error(e);
        }
    };

    // Event Handlers
    const connectionHandler = (_connected: boolean) => {
        connected.value = _connected;
        isConnecting.value = false;
    };
    const participantUisUpdateHandler = (_participantUIs: VideoParticipantUI[]) => (participantUis.value = _participantUIs);
    const audioToggleHandler = (enabled: boolean) => {
        audioEnabled.value = enabled;
        audioMuteLoading.value = false;
    };
    const videoToggleHandler = (enabled: boolean) => {
        videoEnabled.value = enabled;
        videoMuteLoading.value = false;
    };

    const screenSharingActiveHandler = (enabled: boolean) => {
        screenSharingEnabled.value = enabled;
    };
    const pointerActiveHandler = (enabled: boolean) => {
        pointerEnabled.value = enabled;
    };
    const disconnectedHandler = () => emit('end');
    const audioLevelChangeHandler = (val: number) => (audioLevel.value = val);
    const recordingStatusChangeHandler = (enabled: boolean) => {
        if (isRecording.value !== enabled) {
            isRecording.value = enabled;
            showToastMessage(
                enabled ? t('interaction.videoView.record_start_' + import.meta.env.VITE_APP) : t('interaction.videoView.record_stop'),
            );
        }
        recordIsLoading.value = false;
    };

    const showToastMessage = async (message: string, isError = false) => {
        const id = generateId();
        waitId = id;
        toastMessage.value = message;
        toastMessageIsError.value = isError;
        await wait(5000); // time to display the message
        if (id === waitId) toastMessage.value = '';
    };

    const shareScreen = async () => {
        if (screenSharingEnabled.value) {
            await client.stopShareScreen();
        } else {
            screenShareLoading.value = true;
            await client.shareScreen();
            screenShareLoading.value = false;
        }
    };

    const startPointer = async () => {
        if (pointerEnabled.value) {
            await client.stopPointer();
        } else {
            pointerLoading.value = true;
            await client.startPointer();
            pointerLoading.value = false;
        }
    };

    const onLocalVideoExpandClick = () => {
        if (!connected.value) return;
        localVideoExpanded.value = !localVideoExpanded.value;
    };

    function pointerEnabledHandler(enabled: boolean) {
        pointerEnabled.value = enabled;
        pointerLoading.value = false;
    }

    onMounted(async () => {
        client = getInstance() as ApendayVideo;
        client.onWithReplay('connected', connectionHandler);
        client.onWithReplay('disconnected', disconnectedHandler);
        client.onWithReplay('participantUIsUpdate', participantUisUpdateHandler);
        client.onWithReplay('audioActive', audioToggleHandler);
        client.onWithReplay('videoActive', videoToggleHandler);
        client.onWithReplay('screenSharingActive', screenSharingActiveHandler);
        client.onWithReplay('audioLevelChange', audioLevelChangeHandler);
        client.onWithReplay('recordingStatusChange', recordingStatusChangeHandler);
        client.onWithReplay('pointerActive', pointerActiveHandler);
        client.onWithReplay('pointerEnabled', pointerEnabledHandler);

        if (client.connected) {
            await client.attachAllRemoteTracks();
        } else if (import.meta.env.VITE_APP !== 'assist' && isInteraction) {
            await client.createLocalVideoTrack();
            await client.createLocalAudioTrack();
        }
    });

    onUnmounted(async () => {
        await client.disableLocalVideo();
        await client.disableLocalAudio();
        client.off('connected', connectionHandler);
        client.off('disconnected', disconnectedHandler);
        client.off('participantUIsUpdate', participantUisUpdateHandler);
        client.off('audioActive', audioToggleHandler);
        client.off('videoActive', videoToggleHandler);
        client.off('screenSharingActive', screenSharingActiveHandler);
        client.off('audioLevelChange', audioLevelChangeHandler);
        client.off('recordingStatusChange', recordingStatusChangeHandler);
        client.off('pointerActive', pointerActiveHandler);
        client.off('pointerEnabled', pointerEnabledHandler);
    });

    defineExpose({
        onInteractionClose: async () => {
            await client.disableLocalAudio();
            await client.disableLocalVideo();
            await disconnect();
        },
    });
</script>

<style lang="scss">
    .screenshot-preview {
        animation: 1.5s cubic-bezier(0, 0.54, 0.38, 0.99) 0s capturePhoto;
    }

    .screenshot-flash {
        animation: 1.5s flashPhoto;
    }

    @keyframes capturePhoto {
        0% {
            opacity: 0;
            transform: translateY(-5px);
        }
        30% {
            opacity: 100%;
        }
        70% {
            opacity: 100%;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(10px);
        }
    }

    @keyframes flashPhoto {
        0% {
            opacity: 100%;
        }
        40% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
</style>
